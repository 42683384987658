/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-10 10:30:31 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Thursday, February 11th 2021, 10:44:42 pm
# ---------------------------------------------
*/

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './theme-light';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'mdi',
  theme: { options: { customProperties: true }, themes: { light } }
});
