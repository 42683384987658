<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 3:59:49 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:36:46 pm
# ---------------------------------------------
-->

<template>
  <v-col no-gutter class="form-container pa-0" md="8">
    <v-tabs
      :vertical="!$vuetify.breakpoint.mobile"
      slider-color="active"
      active-class="active--text"
      centered
      icons-and-text
      @change="onTabChange"
    >
      <v-tabs-slider color="love"></v-tabs-slider>
      <v-tab>
        <v-icon left>
          mdi-web
        </v-icon>
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-badge-account-outline
        </v-icon>
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-wifi-lock
        </v-icon>
      </v-tab>
      <v-tab-item><UrlRedirect @submit="submit"/></v-tab-item>
      <v-tab-item><BCard @submit="submit"/></v-tab-item>
      <v-tab-item><SSID @submit="submit"/></v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UrlRedirect from '@/components/UrlRedirect';
import SSID from '@/components/SSID';
import BCard from '@/components/BCard';

export default {
  name: 'FormContainer',
  components: {
    UrlRedirect,
    BCard,
    SSID
  },
  computed: {
    ...mapGetters(['tab'])
  },
  methods: {
    ...mapActions(['setTab', 'fetchImage', 'resetForm']),
    submit() {
      this.fetchImage(false);
      setTimeout(() => {
        setTimeout(() => {
          this.fetchImage();
        }, 2000);
      }, 2000);
    },
    onTabChange(tab) {
      this.setTab(tab);
    }
  }
};
</script>

<style scoped>
.form-container {
  border-right: 2px solid var(--v-background-base);
}
</style>
