<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 5:57:19 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:42:37 pm
# ---------------------------------------------
-->

<template>
  <v-card class="mt-2" flat>
    <v-card-text>
      <h2 class="px-5 mb-1">
        <v-icon left large>
          mdi-web
        </v-icon>
        <strong>Url Redirection</strong>
      </h2>

      <v-form
        @submit.prevent="submit"
        ref="redirectForm"
        class="px-5"
        v-model="valid"
        lazy-validation
      >
        <v-col class="ma-0 pa-0">
          <p>
            Generate a QR Code to redirect your users and make it easier for
            them to visit your pages or download your apps.
          </p>
        </v-col>

        <v-col class="px-0 mb-3">
          <h4 class="mt-2 highlight py-2 px-1 rounded">
            <v-icon left>
              mdi-arrow-decision-outline
            </v-icon>
            Redirection Details
          </h4>
        </v-col>

        <v-text-field
          v-model="redirect.destination"
          :rules="[v => !!v || 'Destination URL is required']"
          label="Destination URL"
          required
        >
        </v-text-field>

        <v-text-field
          v-model="redirect.email"
          label="Email"
          :rules="[
            v => !!v || validate.email.test(v) || 'Email address is not valid'
          ]"
          required
        >
        </v-text-field>

        <v-row class="mt-3 mb-5">
          <v-col no-gutters>
            <v-btn color="primary" type="submit" small depressed rounded>
              <v-icon left>mdi-email-send-outline</v-icon>
              Send to my Email
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UrlRedirect',
  data() {
    return { valid: false };
  },
  methods: {
    ...mapActions(['fetchImage', 'resetForm']),
    submit() {
      if (this.$refs.redirectForm.validate()) {
        this.$emit('submit');
      }
    }
  },
  computed: {
    ...mapGetters(['image', 'validate', 'colors', 'redirect'])
  }
};
</script>

<style scoped>
h2 {
  padding: 0 5px 4px 0;
}
</style>
