<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 5:58:45 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:42:28 pm
# ---------------------------------------------
-->

<template>
  <v-card class="mt-2" flat>
    <v-card-text>
      <h2 class="px-5 mb-1">
        <v-icon left large>
          mdi-badge-account-outline
        </v-icon>
        <strong>VCard</strong>
      </h2>
      <p class="px-5">
        Generate a QR Code for your resume and make your contact details
        stand-out!
      </p>
      <v-form
        @submit.prevent="submit"
        ref="cardForm"
        class="px-5"
        v-model="valid"
        lazy-validation
      >
        <v-col class="px-0 mb-3">
          <h4 class="highlight py-2 px-1 rounded">
            <v-icon>
              mdi-badge-account-outline
            </v-icon>
            Personal Details
          </h4>
        </v-col>

        <v-select
          v-model="vcard.prefix"
          :items="maps.vcard.prefixes"
          label="Prefix"
        >
        </v-select>

        <v-row>
          <v-col>
            <v-text-field
              :rules="[v => !!v || 'First name is required']"
              v-model="vcard.first_name"
              label="First name"
              required
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="[v => !!v || 'Last name is required']"
              v-model="vcard.last_name"
              label="Last name"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :rules="[
                v =>
                  !!v || validate.email.test(v) || 'Email address is not valid'
              ]"
              v-model="vcard.email"
              label="Email"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="[v => !!v || 'Phone is required']"
              v-model="vcard.home_phone"
              label="Phone"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-col class="px-0 mb-3">
          <h4 class="mt-2 highlight py-2 px-1 rounded">
            <v-icon>
              mdi-map-marker-outline
            </v-icon>
            Personal Address
          </h4>
        </v-col>
        <v-text-field
          v-model="vcard.home_street"
          label="Street / Building / Apartment name"
          required
        >
        </v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vcard.home_locality"
              label="Locality"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="vcard.home_city" label="City" required>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="vcard.home_country" label="Country" required>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="vcard.home_postal_code"
              label="Postal code"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col class="px-0 mb-3">
          <h4 class="mt-2 highlight py-2 px-1 rounded">
            <v-icon>
              mdi-map-outline
            </v-icon>
            Work Address
          </h4>
        </v-col>
        <v-text-field
          v-model="vcard.work_street"
          label="Street / Building / Apartment name"
          required
        >
        </v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vcard.work_locality"
              label="Locality"
              required
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="vcard.work_city" label="City" required>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="vcard.work_country" label="Country" required>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="vcard.work_postal_code"
              label="Postal code"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-3 mb-5">
          <v-col no-gutters>
            <v-btn color="primary" type="submit" small depressed rounded>
              <v-icon left>mdi-email-send-outline</v-icon>
              Send to my Email
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BCard',
  data() {
    return { valid: true };
  },
  methods: {
    ...mapActions[('fetchImage', 'resetForm')],
    submit() {
      if (this.$refs.cardForm.validate()) {
        this.$emit('submit');
      }
    }
  },
  computed: {
    ...mapGetters(['image', 'validate', 'colors', 'maps', 'vcard'])
  }
};
</script>

<style scoped>
h2 {
  padding: 0 5px 4px 0;
}
</style>
