<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-10 10:29:29 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 7:39:37 pm
# ---------------------------------------------
-->

<template>
  <v-container class="offset-md-2 col-md-8">
    <v-row class="mt-logo mb-5">
      <Header />
    </v-row>
    <v-row>
      <v-col>
        <v-row class="box">
          <FormContainer />
          <QRCode />
        </v-row>
      </v-col>
    </v-row>
    <Dialog />
  </v-container>
</template>

<script>
import Header from '@/components/Header';
import QRCode from '@/components/QRCode';
import FormContainer from '@/components/FormContainer';
import Dialog from '@/components/Dialog';

export default {
  name: 'Home',
  components: {
    Header,
    QRCode,
    Dialog,
    FormContainer
  }
};
</script>

<style scoped>
.box {
  min-height: 550px;
  background: white;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);
}
.mt-logo {
  margin-top: 30px !important;
}
/* MEDIA */
@media screen and (max-width: 960px) {
  .mt-logo {
    margin-top: 10px !important;
  }
}
</style>
