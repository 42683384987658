<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 3:57:42 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:38:17 pm
# ---------------------------------------------
-->

<template>
  <v-col class="qr-holder hidden-sm-and-down" md="4">
    <v-row class="justify-center mt-8 mb-5">
      <img
        v-if="image != ''"
        alt="QRnxt - Generated QR Code"
        class="qr"
        :src="image"
        width="220"
        height="220"
      />
      <v-card
        :loading="image == ''"
        v-if="image == ''"
        height="220"
        width="220"
        flat
        class="text-center"
      >
        <v-card-text class="text-center pt-15">
          <v-icon class="display-1 mb-4">mdi-qrcode-scan</v-icon>
          <p class="display-5">Generating your QR Code</p>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="pl-8 pr-5">
      <v-col no-gutters class="py-0 px-3" md="12">
        <v-text-field
          :rules="[
            v => !!v || validate.color.test(v) || 'Background color is required'
          ]"
          v-model="colors.light"
          label="Background color:"
          required
        >
        </v-text-field>
      </v-col>
      <v-col no-gutters class="py-0 px-3" md="12">
        <v-text-field
          :rules="[
            v => !!v || validate.color.test(v) || 'Foreground color is required'
          ]"
          v-model="colors.dark"
          label="Foreground color:"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="justify-center mt-5">
      <v-btn class="primary--text" @click="updateQR" small depressed rounded>
        <v-icon left>mdi-qrcode-edit</v-icon>
        Update QR Code
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'QRCode',
  methods: {
    ...mapActions(['fetchImage']),
    updateQR() {
      this.fetchImage();
    }
  },
  computed: {
    ...mapGetters(['image', 'validate', 'colors'])
  },
  created() {
    this.fetchImage();
  }
};
</script>

<style scoped>
.qr-holder {
  background-color: var(--v-background-base);
}

.qr {
  box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.2);
}
</style>
