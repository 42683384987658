<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 5:59:40 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:42:41 pm
# ---------------------------------------------
-->

<template>
  <v-card class="mt-2" flat>
    <v-card-text>
      <h2 class="px-5 mb-1">
        <v-icon left large>
          mdi-wifi-lock
        </v-icon>
        <strong>SSID</strong>
      </h2>
      <p class="px-5">
        Generate a QR Code to share your WIFI connection at home or your office.
      </p>
      <v-form
        @submit.prevent="submit"
        ref="wifiForm"
        class="px-5"
        lazy-validation
      >
        <v-col class="px-0 mb-3">
          <h4 class="mt-2 highlight py-2 px-1 rounded">
            <v-icon left>
              mdi-wifi-cog
            </v-icon>
            Your WIFI Details
          </h4>
        </v-col>

        <v-select
          :rules="[v => !!v || 'Encryption is required']"
          :items="maps.wifi.types"
          v-model="wifi.encryption"
          item-text="name"
          label="Encryption"
          required
        >
        </v-select>

        <v-text-field
          :rules="[v => !!v || 'Your Network Name or SSID is required']"
          v-model="wifi.ssid"
          label="SSID / Network name"
          required
        >
        </v-text-field>

        <v-text-field
          :rules="[v => !!v || 'Your wifi Password is required']"
          v-model="wifi.password"
          type="password"
          label="Password"
          required
        >
        </v-text-field>

        <v-text-field
          :rules="[
            v => !!v || validate.email.test(v) || 'Email address is not valid'
          ]"
          v-model="wifi.email"
          label="Email"
          required
        >
        </v-text-field>

        <v-row class="mt-3 mb-5">
          <v-col no-gutters>
            <v-btn color="primary" type="submit" small depressed rounded>
              <v-icon left>mdi-email-send-outline</v-icon>
              Send to my Email
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SSID',
  methods: {
    ...mapActions[('fetchImage', 'resetForm')],
    submit() {
      if (this.$refs.wifiForm.validate()) {
        this.$emit('submit');
      }
    }
  },
  computed: {
    ...mapGetters(['image', 'validate', 'colors', 'wifi', 'maps'])
  }
};
</script>

<style scoped>
h2 {
  padding: 0 5px 4px 0;
}
</style>
