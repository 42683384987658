/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-10 10:29:29 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Friday, February 12th 2021, 1:43:32 pm
# ---------------------------------------------
*/

import Vue from 'vue';
import Vuex from 'vuex';
import qr from '@/store/modules/qr';
import form from '@/store/modules/form';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { qr, form }
});
