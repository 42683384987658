<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-13 6:10:48 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 7:51:03 pm
# ---------------------------------------------
-->
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card color="primary pa-3 text-center" dark>
        <v-icon x-large>mdi-qrcode</v-icon>
        <v-card-text class="text-center my-3">
          We are now sending your QR Code.
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-3 mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Dialog',
  computed: {
    ...mapGetters(['dialog'])
  }
};
</script>

<style></style>
