/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-10 10:29:29 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Thursday, February 11th 2021, 1:10:35 am
# ---------------------------------------------
*/

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
