<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 2:12:43 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Friday, February 12th 2021, 4:04:15 pm
# ---------------------------------------------
-->

<template>
  <v-row class="header-container sm-2 px-5 pt-3 justify-md-left">
    <img alt="QRNxt" src="../assets/logo.png" height="40" />
  </v-row>
</template>

<script>
export default {
  name: 'Header',
  setup() {}
};
</script>

<style scoped>
.header-container {
  position: relative;
  height: 40px;
  margin: 0;
}

.subtitle {
  font-family: 'Open Sans Condensed';
  padding: 0;
  margin: 0;
}
</style>
