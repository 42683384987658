/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-12 1:40:43 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 8:30:43 pm
# ---------------------------------------------
*/

// import * as QRCode from 'qrcode';
import Web from '@/services/qr';

const DEFAULT_CONTENT = 'https://www.qrnxt.com';
const QR_DEFAULTS = {
  margin: 1,
  width: 220,
  color: { light: '#E6E6E6FF', dark: '#3D405BFF' },
  errorCorrectionLevel: 'M'
};

const tryQR = async (rs, download) => {
  // local
  // const image = await new Promise((resolve, reject) => {
  //   QRCode.toDataURL(data.content, data.code, (err, url) => {
  //     if (err) return reject(err);
  //     resolve(url);
  //   });
  // });

  // remote for now
  let params = {};
  switch (true) {
    case rs.form.tab == 0:
      params = { ...rs.form['redirect'] };
      break;
    case rs.form.tab == 1:
      params = { ...rs.form['vcard'] };
      break;
    case rs.form.tab == 2:
      params = { ...rs.form['wifi'] };
      break;
  }

  console.log('[STATE]', rs);

  params['download'] = download;
  params['light'] = rs.qr.code['color']['light'];
  params['dark'] = rs.qr.code['color']['dark'];

  console.log('[PARAMS]', params);
  const image = await new Promise((resolve, reject) => {
    Web.generateImage(params)
      .then(res => {
        resolve(res.data['image']);
      })
      .catch(err => {
        reject(err);
      });
  });
  return image;
};

const state = {
  dialog: false,
  status: 'ready',
  content: Object.assign({}, DEFAULT_CONTENT),
  code: Object.assign({}, QR_DEFAULTS),
  image: ''
};

const getters = {
  dialog: state => state.dialog,
  image: state => state.image,
  colors: state => state.code.color
};

const actions = {
  async fetchImage({ commit, rootState }, download = true) {
    console.log('DOWNLOAD', download);

    if (!download) {
      commit('SET_DIALOG', true);
    } else {
      commit('SET_IMAGE', '');
    }

    commit('UPDATE_STATUS', 'loading');
    const image = await tryQR(rootState, download);

    if (!download) {
      setTimeout(() => {
        commit('SET_DIALOG', false);
        commit('RESET_COLORS', { light: '#E6E6E6FF', dark: '#3D405BFF' });
      }, 3000);
    } else {
      commit('SET_IMAGE', image);
    }
  }
};

const mutations = {
  UPDATE_CODE: (state, code) => (state.code = code),
  UPDATE_STATUS: (state, status) => (state.status = status),
  SET_CONTENT: (state, content) => (state.content = content),
  SET_IMAGE: (state, image) => (state.image = image),
  SET_DIALOG: (state, dialog) => (state.dialog = dialog),
  RESET_COLORS: (state, colors) => (state.colors = colors)
};

export default {
  state,
  getters,
  actions,
  mutations
};
