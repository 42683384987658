/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-12 9:47:13 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 4:55:43 pm
# ---------------------------------------------
*/

import axios from 'axios';

const API_URL = 'https://api.qrnxt.com';
// const API_URL = 'http://localhost:3000';

export default {
  generateImage(params) {
    const options = {
      'Content-type': 'application/json'
    };
    return axios.post(`${API_URL}/save/`, JSON.stringify(params), options);
  }
};
