<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-10 10:29:29 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Friday, February 12th 2021, 7:44:32 pm
# ---------------------------------------------
-->

<template>
  <v-app class="primary--text">
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer';

export default {
  name: 'App',
  components: {
    Footer
  },
  created() {
    document.title = 'QRNxt - Just another free QR Code generator';
  }
};
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(
    90deg,
    var(--v-background-base) 0%,
    var(--v-background-base) 61%,
    var(--v-link-base) 61%,
    var(--v-link-base) 100%
  );
}

a,
a:visited {
  text-decoration: none;
  transition: color 0.3s ease-out;
}

a:active,
a:hover {
  color: var(--v-link-base) !important;
  transition: color 0.4s ease-in-out;
}

/* MEDIA */
@media screen and (max-width: 960px) {
  #app {
    font-family: 'Open Sans';
    background: var(--v-background-base);
  }
}
</style>
