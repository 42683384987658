/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-12 1:40:56 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Saturday, February 13th 2021, 7:56:02 pm
# ---------------------------------------------
*/
const FORM_MAPS = {
  wifi: {
    types: [
      { name: 'None', value: 'none' },
      { name: 'WPA/WPA2-PSK', value: 'WPA' },
      { name: 'WEP', value: 'WEP' }
    ]
  },
  vcard: {
    prefixes: [
      '1st Lt',
      'Adm',
      'Atty',
      'Brother',
      'Capt',
      'Chief',
      'Cmdr',
      'Col',
      'Dean',
      'Dr',
      'Elder',
      'Father',
      'Gen',
      'Gov',
      'Hon',
      'Lt Col',
      'Maj',
      'MSgt',
      'Mr',
      'Mrs',
      'Ms',
      'Prince',
      'Prof',
      'Rabbi',
      'Rev',
      'Sister'
    ]
  }
};
const DEFAULT_REDIRECT = {
  type: 'redirect',
  destination: 'https://www.qrnxt.com'
};
const DEFAULT_VCARD = {
  type: 'vcard',
  prefix: '',
  first_name: '',
  last_name: '',
  title: '',

  // home
  home_phone: '',
  home_street: '',
  home_locality: '',
  home_city: '',
  home_country: '',
  home_postal_code: '',

  // work
  work_phone: '',
  work_street: '',
  work_locality: '',
  work_city: '',
  work_country: '',
  work_postal_code: ''
};
const DEFAULT_WIFI = {
  type: 'wifi',
  encryption: '',
  ssid: '',
  password: '',
  email: ''
};
const REGEXS = {
  color: /^#[A-Fa-f0-9]{8}/gi,
  email: /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/gi
};

const state = {
  maps: { wifi: FORM_MAPS.wifi, vcard: FORM_MAPS.vcard },
  wifi: Object.assign({}, DEFAULT_WIFI),
  vcard: Object.assign({}, DEFAULT_VCARD),
  redirect: Object.assign({}, DEFAULT_REDIRECT)
};

const getters = {
  maps: () => FORM_MAPS,
  validate: () => REGEXS,
  vcard: state => state.vcard,
  redirect: state => state.redirect,
  wifi: state => state.wifi,
  tab: state => state.tab
};

const actions = {
  resetForm({ commit, rootState }) {
    switch (true) {
      case rootState.form.tab == 0:
        commit('RESET_REDIRECT', DEFAULT_REDIRECT);
        break;
      case rootState.form.tab == 1:
        commit('RESET_VCARD', DEFAULT_VCARD);
        break;
      case rootState.form.tab == 2:
        commit('RESET_WIFI', DEFAULT_WIFI);
        break;
    }
    commit('RESET_WIFI', DEFAULT_WIFI);
  },
  setTab({ commit }, current_tab) {
    commit('SET_TAB', current_tab);
  }
};

const mutations = {
  SET_TAB: (state, tab) => (state.tab = tab),
  RESET_REDIRECT: (state, redirect) => (state.redirect = redirect),
  RESET_WIFI: (state, wifi) => (state.wifi = wifi),
  RESET_VCARD: (state, vcard) => (state.vcard = vcard)
};

export default {
  state,
  getters,
  actions,
  mutations
};
