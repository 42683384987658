<!--
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 4:02:27 pm
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Friday, February 12th 2021, 7:42:47 pm
# ---------------------------------------------
-->

<template>
  <v-footer padless>
    <v-col class="footer offset-md-2 caption" cols="12">
      A product of
      <a href="http://nixth.com" target="_blank">Nixth Media</a>. Made with
      <v-icon class="love--text">mdi-cards-heart</v-icon> in the Philippines.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  setup() {}
};
</script>

<style scoped>
.v-footer {
  background: none !important;
  box-shadow: none !important;
}

.footer {
  text-align: left;
}

footer i {
  font-size: 12px !important;
}

/* MEDIA */
@media screen and (max-width: 960px) {
  .footer {
    text-align: center;
  }
}
</style>
