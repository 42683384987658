/*
# ---------------------------------------------
# Author: Ashier de Leon - ashier@gmail.com>
# Date: 2021-02-11 1:17:27 am
# Last Modified by: Ashier de Leon - ashier@gmail.com>
# Last Modified time: Friday, February 12th 2021, 7:35:53 pm
# ---------------------------------------------
*/

// #f4f1de // light yellow
// #e07a5f // red
// #3d405b // blue
// #81b29a // green
// #f2cc8f // yellow

export default {
  primary: { base: '#3d405b' },
  secondary: '#81b29a',
  accent: '#81b29a',
  error: '#e07a5f',
  info: '#f2cc8f',
  success: '#81b29a',
  bg: '#E6E6E6',
  love: '#e07a5f',
  active: '#e07a5f',
  highlight: { base: '#f4f1de' },
  selected: { base: '#e07a5f' },
  activebg: { base: '#EAEAEA' },
  link: { base: '#81b29a' },
  background: { base: '#E6E6E6' }
};
